import React from 'react';
import Layout from '../../components/layout/layout.component';
import './contact-me.style.scss';
import emailjs from 'emailjs-com';

const Contact = () => {
  const sendMessage = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_m4039y8',
        'template_px5vn2k',
        e.target,
        'user_IjKfHQxCFvIIBk3bZGVEj'
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
    e.target.reset();
  };

  return (
    <Layout>
      <div className='contact-page'>
        <form className='contact-form' onSubmit={sendMessage}>
          <h1>Let's get in touch!</h1>
          <input className='input' placeholder='name' type='text' name='name' />
          <input
            className='input'
            placeholder='email'
            type='email'
            name='user_email'
          />
          <textarea
            className='input input-message'
            placeholder='message'
            rows='2'
            type='text'
            name='message'
            // onKeyPress={(e) => {
            //   if (e.key === 'Enter') e.preventDefault();
            // }}
          />
          <button className='contact-button' type='submit' value='send'>
            Send Message
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Contact;
