import React from 'react';
import Layout from '../../components/layout/layout.component';
import './blog.style.scss';

const Blog = () => {
  return (
    <Layout>
      <div className='blog'>
        <h1
          style={{
            margin: '0',
            padding: '100px',
          }}
        >
          Coming soon!
        </h1>
      </div>
    </Layout>
  );
};

export default Blog;
