import React from 'react';
import './button.style.scss';

const Button = ({ url, children }) => {
  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      className='button'
      href={`${url}`}
    >
      {children}
    </a>
  );
};

export default Button;
