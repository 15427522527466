import React from 'react';
import './project.style.scss';

import ProjectCard from './project-card';

const Project = ({ title, description, image, id, codeUrl, webUrl }) => {
  console.log(title);
  return (
    <div className={`project-container ${id === 1 ? 'project-reverse' : ''}`}>
      {description && title ? (
        <div className='project-text'>
          <h1 className='project-title'>{title}</h1>
          <p className='project-description'>{description}</p>
        </div>
      ) : null}
      <ProjectCard image={image} codeUrl={codeUrl} webUrl={webUrl} />
    </div>
  );
};

export default Project;
