import React from 'react';

import './card-stack.style.scss';

const CardStack = ({ image, title, description }) => (
  <div className='card-stack'>
    <div className='card-stack__container'>
      <picture className='card-stack__picture'>
        <img
          className='card-stack__logo'
          src={`/assets/icons/${image}`}
          alt='icon'
        />
      </picture>
      <div className='card-stack__description'>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

export default CardStack;
