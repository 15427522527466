const Description = () => {
  return (
    <div className='intro-description'>
      <p>
        I'm a programmer who values lifelong learning and thrives on building
        quality systems that surpass end-users’ expectations. I am a quick
        learner, always up to learn new things and improve my skill set through
        building projects.
      </p>
      <p>
        Currently I am a full-stack developer with hands-on experience working
        with JavaScript, React and Rest APIs. I am considered a team-player
        because I like to help others and tend to work well within groups. I
        also love learning cutting edge technologies which make developers'
        lives easier.
      </p>
    </div>
  );
};

export default Description;
