import React from 'react';
import './title-section.style.scss';
import TypeWriterEffect from 'react-typewriter-effect';

const TitleSection = () => {
  return (
    <div className='title-section'>
      <img
        className='background-image'
        src='/assets/background_image.jpeg'
        alt='background'
      />
      <div className='title-container'>
        <h1 className='name-is'>
          <span className='name'>Angie_Duong</span>
          <span className='is'>.is()</span>
        </h1>
        <div className='type-writer'>
          <TypeWriterEffect
            text={`/* A Developer & Creative Designer */`}
            typeSpeed={60}
            cursorColor='rgb(73, 98, 119)'
            startDelay={200}
          />
        </div>
      </div>
    </div>
  );
};

export default TitleSection;
