import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import './App.css';

import Homepage from './page/homepage/homepage.component';
import Blog from './page/blog/blog.component';
import Contact from './page/contact-me/contact-me.component';

// import Header from './components/header/header.component';
// import Footer from './components/footer/footer.component';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Homepage />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/contact' element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
