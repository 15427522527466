import React from 'react';
import GithubIcon from '../icons/github';
import Linkedin from '../icons/linkedin';
import './footer.style.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <span>Email: th.nguyenduong94@gmail.com</span>
      <div>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://github.com/Angieduong7'
        >
          <GithubIcon />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.linkedin.com/in/angie07/'
        >
          <Linkedin />
        </a>
      </div>
      <small>© Angie Duong</small>
    </div>
  );
};

export default Footer;
