import React from 'react';
import Button from '../button/button.component';
import './project-card.style.scss';

const ProjectCard = ({ image, webUrl, id, codeUrl }) => {
  return (
    <div className='project-image-container'>
      <div className='image-box'>
        <img className='project-image' src={`/assets/${image}`} alt='{title}' />
        <div className='project-image-buttons'>
          <Button key={id} url={webUrl}>
            View Web
          </Button>
          <Button key={id} url={codeUrl}>
            View Code
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ProjectCard;
