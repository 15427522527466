import React from 'react';

import { NavLink } from 'react-router-dom';

import './header.style.scss';

const Header = () => {
  return (
    <div className='nav'>
      <NavLink className='nav__item' activeClassName='active' to='/'>
        About
      </NavLink>
      <NavLink className='nav__item' activeClassName='active' to='/blog'>
        Blog
      </NavLink>
      <NavLink className='nav__item' activeClassName='active' to='/contact'>
        Contact
      </NavLink>
    </div>
  );
};

// const navItem = document.querySelector('.nav__item');

export default Header;
