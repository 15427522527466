import React from 'react';
import Introduction from '../../components/introduction/introduction.component';
import TitleSection from '../../components/title-section/title-section.component';
import Layout from '../../components/layout/layout.component';
import Stacks from '../../components/stacks/stacks.component';
import './homepage.style.scss';
import MyProject from '../../components/my-projects/my-projects.component';

const Homepage = () => {
  return (
    <div className='homepage'>
      <Layout>
        <TitleSection />
        <Introduction />
        <Stacks />
        <MyProject />
      </Layout>
    </div>
  );
};

export default Homepage;
