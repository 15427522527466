export default function DownloadIcon({ className, style }) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      enableBackground='new 0 0 512 512'
      xmlSpace='preserve'
      className={className}
      style={style}
    >
      <path d='M442.2,186.2H302.5V0h-93.1v186.2H69.8L256,418.9L442.2,186.2z M0,465.5V512h512v-46.5H0z' />
    </svg>
  );
}
