import React from 'react';
import './introduction.style.scss';
import Description from './description.component';
import GithubIcon from '../icons/github';
import Linkedin from '../icons/linkedin';
import DownloadIcon from '../icons/download';

const Introduction = () => {
  return (
    <div className='introduction-section'>
      <div className='intro-text'>
        <h1 className='greeting'> Hi there, I'm Angie!</h1>
        <Description />
        <div className='icons-container'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='../assets/angie-duong-CV.pdf'
            className='download-CV-container'
            style={{ textDecoration: 'none' }}
          >
            <DownloadIcon
              style={{ fill: '#fff', width: '1.3rem', height: '1.3rem' }}
            />
            <span>My CV</span>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://github.com/Angieduong7'
          >
            <GithubIcon />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.linkedin.com/in/angie07/'
          >
            <Linkedin />
          </a>
        </div>
      </div>
      <figure className='image-container'>
        <img
          className='profile-image'
          src='/assets/profile_image1.png'
          alt='profile'
        />
      </figure>
    </div>
  );
};

export default Introduction;
