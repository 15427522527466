import React from 'react';
import './my-projects.style.scss';
import ProjectCard from './project-card';
import Project from './project.component';

const projectList = [
  {
    title: `Angie's Clothes Boutique`,
    id: 1,
    description:
      'A fully complete e-Commerce platform built on React using Redux, React-router, GraphQL, Context API. This app also uses the Stripe API to handle payment, integrates with Firebase to handle authentication and tests with Jest, Enzyme and snapshot testing',
    image: '/angieshop.png',
    codeUrl: 'https://github.com/Angieduong7/angie-clothes-boutique',
    webUrl: 'https://angie-clothes-boutique.vercel.app/',
  },
  {
    title: `Forkify - The Best Recipes`,
    id: 2,
    description:
      'A modern and beautiful application for searching the recipes using the latest ES6 JavaScript and implementing object-oriented programming, AJAX calls. You also can bookmark them and create your own recipes with certain number of servings.',
    image: '/forkify.png',
    codeUrl: 'https://github.com/Angieduong7/forkify-app',
    webUrl: 'https://angieduong7.github.io/forkify-app/',
  },
  {
    title: `Natour - Tours Booking`,
    id: 3,
    description: '',
    image: '/natour.png',
    codeUrl: 'https://github.com/Angieduong7/tour-booking',
    webUrl: 'hhttps://angieduong7.github.io/tour-booking/',
  },
  {
    title: `Bankist App`,
    id: 4,
    description: '',
    image: '/bankist.png',
    codeUrl: 'https://github.com/Angieduong7/Bankist-App',
    webUrl: 'https://angieduong7.github.io/Bankist-App/',
  },
  {
    title: `Trillo`,
    id: 5,
    description: '44444444444444444444444',
    image: '/trillo.png',
    codeUrl: 'https://github.com/Angieduong7/Trillo',
    webUrl: 'https://angieduong7.github.io/Trillo/#',
  },
  {
    title: `Monster Rolorex`,
    id: 6,
    description: '',
    image: '/monster.png',
    codeUrl: 'https://github.com/Angieduong7/monsters-rolorex',
    webUrl: 'https://angieduong7.github.io/monsters-rolorex/',
  },
];

const MyProject = () => {
  console.log(projectList.slice(0, 2));
  return (
    <div className='my-project'>
      <div className='my-project-background'></div>
      <div className='my-project-section'>
        <h1 className='my-project-title'>My Projects</h1>
        <p className='my-project-intro'>
          Probably a section you are most interested in. Have fun exploring some
          of my recent projects.
        </p>
        <div className='project-details'>
          {projectList
            .slice(0, 2)
            .map(({ id, title, description, image, codeUrl, webUrl }) => {
              return (
                <Project
                  id={id}
                  key={id}
                  title={title}
                  description={description}
                  image={image}
                  codeUrl={codeUrl}
                  webUrl={webUrl}
                />
              );
            })}
        </div>
        <div className='other-projects'>
          <h1 className='other-project-title'>Other Interesting Projects</h1>
          <div className='other-projects-container'>
            {projectList.slice(2).map(({ id, image, codeUrl, webUrl }) => {
              return (
                <ProjectCard
                  key={id}
                  image={image}
                  codeUrl={codeUrl}
                  webUrl={webUrl}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProject;
